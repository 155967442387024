<template>
  <div>
    <Header />
    <div class="page-title">
      <div class="container">
        <div class="title">Produtos</div>
      </div>
    </div>

    <section>
      <div class="container">
        <div
          class="flex-start product-intro-holder desktop12 tablet 12 mobile12 mb-2"
        >
          <div class="img-holder desktop4">
            <img :src="product.image" :alt="product.name" />
            <span>{{ product.imageLabel }}</span>
          </div>
          <div
            class="content-holder desktop8 pl-3-desktop mt-2-tablet mt-2-mobile"
          >
            <div class="flex-between mb-2">
              <h1 class="prod-title">{{ product.name }}</h1>
              <div class="share-options desktop6 tablet6 mobile12 mt-2-mobile">
                <div @click="shareOnWhatsapp" class="item whats">
                  <font-awesome-icon :icon="['fab', 'whatsapp']" />
                  <span>WhatsApp</span>
                </div>
                <!-- <div class="item">
                  <font-awesome-icon :icon="['fas', 'envelope']" />
                  <span>E-mail</span>
                </div> -->
                <div @click="copyUrl" class="item link">
                  <font-awesome-icon :icon="['fas', 'copy']" />
                  <span>Copiar Link</span>
                </div>
                <div @click="shareOnFacebook" class="item fb">
                  <font-awesome-icon :icon="['fab', 'facebook']" />
                  <span>Facebook</span>
                </div>
                <div @click="shareOnLinkedin" class="item linkedin">
                  <font-awesome-icon :icon="['fab', 'linkedin']" />
                  <span>LinkedIn</span>
                </div>
              </div>
            </div>
            <div class="html-content">
              <div v-html="product.summary"></div>
            </div>
          </div>
        </div>

        <div class="html-content">
          <div v-html="product.description"></div>
        </div>
      </div>
    </section>

    <section class="team">
      <div class="container">
        <div class="text">
          <h1>Encontre nosso time</h1>
          <p>
            A Tradecorp conta com uma equipe altamente qualificada e
            comprometida com o resultado do produtor rural brasileiro.
          </p>
        </div>

        <router-link
          to="/nosso-time"
          class="btn border icon yellow mt-2-mobile mr-auto-mobile"
        >
          <span>PESQUISE</span>
          <font-awesome-icon :icon="['fas', 'chevron-right']" />
        </router-link>
      </div>
    </section>

    <Footer />
  </div>
</template>
<script>
import http from "@/http";
import Header from "@/components/Header";
import Footer from "@/components/Footer";
export default {
  name: "ProdutoInterna",
  components: {
    Header,
    Footer,
  },
  data() {
    return {
      product: {
        id: "",
        name: "",
        description: "",
        summary: "",
        image: "",
        imageLabel: "",
        categoryId: "",
        category: {
          name: "",
        },
        cultures: [],
        nutrients: [],
      },
      loadingProducts: false,
    };
  },
  created() {
    this.loadingProducts = true;
    http
      .get(`Product?id=${this.$route.params.id}`)
      .then((res) => {
        this.product = res.data;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loadingProducts = false;
      });
  },
  methods: {
    shareOnWhatsapp() {
      let link = "https://api.whatsapp.com/send?text=";
      link += `Tradecorp - Produto %0a ${encodeURIComponent(
        document.location.href
      )}`;
      window.open(link, "_blank");
    },
    shareOnFacebook() {
      window.open(
        `https://www.facebook.com/sharer.php?u=${document.location.href}`,
        "sharer",
        "toolbar=0,status=0,width=500,height=500"
      );
    },
    shareOnLinkedin() {
      var url = location.href;
      window.open(
        "https://www.linkedin.com/shareArticle?mini=true&url=" +
          encodeURIComponent(url),
        "",
        "left=0,top=0,width=500,height=500,personalbar=0,toolbar=0,scrollbars=0,resizable=0"
      );
    },
    copyUrl() {
      const el = document.createElement("textarea");
      el.value = location.href;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      this.$swal({
        toast: true,
        icon: "success",
        title: "URL Copiada!",
        position: "bottom-right",
        showConfirmButton: false,
        timer: 4000,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.product-intro-holder .img-holder {
  position: relative;
  img {
    max-width: 100%;
  }
  span {
    position: relative;
    display: block;
    margin: 10px auto 0 auto;
    text-align: center;
    color: #929292;
  }
}

.prod-title {
  font-family: fontBold;
  font-size: 2em;
  color: var(--secondary);
}

.share-options {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 8px;
  .item {
    position: relative;
    padding: 5px 10px;
    background-color: #444;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0 8px;
    cursor: pointer;
    &.whats {
      background-color: var(--primary);
    }
    &.link {
      background-color: #178bf4;
    }
    &.fb {
      background-color: #3b5998;
    }
    &.linkedin {
      background-color: #0077b5;
    }
    svg {
      color: #fff;
    }
    span {
      color: #fff;
    }
  }
}

.html-content {
  position: relative;
  max-width: 100%;
  * {
    max-width: 100%;
  }
}

section.team {
  clear: both;
  background-color: #00a34b;
  .container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    .text {
      position: relative;
      width: 100%;
      max-width: 480px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      h1 {
        position: relative;
        width: 100%;
        font-size: 2.4em;
        color: #fff;
      }
      p {
        color: #fff;
        font-size: 1.1em;
        margin-top: 15px;
      }
    }
    .btn {
      margin: 0;
      span {
        letter-spacing: 2px;
      }
    }
  }
}
</style>
